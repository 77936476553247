<template>
  <div>
    <div class="contain">
      <div class="title">{{ detail.title }}</div>
      <div class="contain1_1">
        <div class="nav">
          <div
            class="nav_item"
            v-for="(item, index) in breadcrumb"
            :key="index"
            @click="goPage(item)"
          >
            <div>{{ item.name }}</div>
            <div v-if="breadcrumb.length - 1 != index">/</div>
          </div>
        </div>
      </div>
      <div class="contain1_1_line"></div>
      <div class="num">
        <div class="num_left">
          <img src="@/assets/image/icon37.png" alt="" />{{
            detail.view_count || 0
          }}阅读
        </div>
        <div class="num_right">
          最后更新：{{ detail.updated_at.split("T")[0] }}&nbsp;{{
            detail.updated_at.split("T")[1].split("Z")[0]
          }}
        </div>
      </div>
      <div v-html="processedRichTextContent" class="richText"></div>
    </div>
  </div>
</template>

<script>
import { get } from "/utils/request";
export default {
  data() {
    return {
      breadcrumb: [
        { id: "", name: "首页" },
        { id: 2, name: "公告列表" },
        { id: 3, name: "公告详情" },
      ],
      detail: null,
      content:
        "<div>文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字字</div>",
    };
  },
  created() {
    this.getDetail();
  },
  computed: {
    processedRichTextContent() {
      return this.processImages(this.detail.content);
    }
  },
  methods: {
    goPage(item) {
      console.log(item);
      if (item.name == "首页") {
        this.$router.replace("/");
        return;
      }
      if (item.name == "公告列表") {
        this.$router.push("/noticeList");
        return;
      }
    },
    getDetail() {
      // /api/v1/article?identifier=price
      get("/article?identifier=" + this.$route.query.identifier).then((res) => {
        console.log(res);
        this.detail = res;
      });
    },
    processImages(content) {
      // 正则匹配图片路径
      const regex = /<img.*?src=["'](.*?)["']/g;
      let result;
      let newContent = content;
      // 使用正则表达式找到所有图片路径
      while ((result = regex.exec(content)) !== null) {
        const imagePath = result[1];
        // 拼接完整图片路径
        const fullImagePath = this.$imgURL + imagePath;
        // 替换原始图片路径并添加样式
        newContent = newContent.replace(
          `<img src="${imagePath}"`, 
          `<img src="${fullImagePath}" style="max-width:100%;height:auto;"`
        );
      }
      return newContent;
    }
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 17px auto 50px;
  padding: 20px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.9px;
    margin-bottom: 10px;
  }
  .contain1_1 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .nav {
      display: flex;
      align-items: center;
      .nav_item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
        cursor: pointer;
      }
    }
  }
  .contain1_1_line {
    height: 1px;
    background: #f4f4f4;
  }
  .num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .num_left {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.7px;
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }
    }
    .num_right {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.7px;
      margin: 20px 0;
    }
  }
  .richText {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.7px;
  }
}
</style>